import React, { useEffect, useRef, useState } from 'react';
import W from "../assets/images/health-care.webp";
import X from "../assets/images/real-estate.webp";
import Y from "../assets/images/education.webp";
import Z from "../assets/images/e-commerce.webp";
import engineering from "../assets/images/engineering.webp";

function Home3() {


    const [animate, setAnimate] = useState(false);

    const windowWidth = window.innerWidth;


    function handleAnimateImage(){
      setAnimate(true);
    }

    const animateImageRef = useRef(null);


    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            handleAnimateImage(); 
          }
        },
        {
          root: null, 
          rootMargin: '0px',
          threshold: (windowWidth > 320) ?  0.3 : 0, 
        }
      );
  
      const target = animateImageRef.current;
      if (target) {
        observer.observe(target);
      }
  
      return () => {
        if (target) {
          observer.unobserve(target);
        }
      };
    }, []);

    
    const htag={
        paddingTop:'2em',
        paddingBottom:'1em'
      };
    return (
        <div className='container'>
        <h1 style={htag} >Industries We Serve</h1>
        <div ref={animateImageRef} className="card-container" style={{padding : 0}}>
        <div className="card">
          <img src={W} alt="Card" className={`card-image card-Image-1 ${animate ? "active" : ""}`} />
        <div className="card-content">
          <h3 className="card-title">Healthcare</h3>
          <p className="card-paragraph smaller">
          In the health sector, trust and reliability are paramount. We develop robust digital campaigns that build trust, increase patient engagement, and enhance your online presence. From healthcare providers to wellness brands, our strategies ensure your message reaches the right audience.
          </p>
        </div>
      </div>

      
      <div className="card">
          <img src={X} alt="Card" className={`card-image card-Image-2 ${animate ? "active" : ""}`} />
        
        <div className="card-content">
          <h3 className="card-title">Real Estate</h3>
          <p className="card-paragraph smaller">
          Stand out in the competitive real estate market with our targeted digital marketing solutions. We help real estate firms attract potential buyers and renters, showcase properties effectively, and drive conversions through compelling digital experiences.
          </p>
        </div>
      </div>

      
      <div className="card">
          <img src={Y} alt="Card" className={`card-image card-Image-3 ${animate ? "active" : ""}`} />
        
        <div className="card-content">
          <h3 className="card-title">Education</h3>
          <p className="card-paragraph smaller">
          Empower your institution with innovative digital strategies that enhance student engagement, streamline admissions, and boost enrollment. Our solutions are designed to create impactful online experiences that resonate with prospective students and their families.
          </p>
        </div>
      </div>

      
      <div className="card">
          <img src={Z} alt="Card" className={`card-image card-Image-4 ${animate ? "active" : ""}`} />
        
        <div className="card-content">
          <h3 className="card-title">E-Commerce</h3>
          <p className="card-paragraph smaller">
          Boost your online sales with our comprehensive ecommerce solutions. We optimise your digital storefront, enhance user experience, and implement targeted marketing campaigns to drive traffic, increase conversions, and maximise revenue.
          </p>
        </div>
      </div>

      
      <div className="card">
          <img src={engineering} alt="Card" className={`card-image card-Image-5 ${animate ? "active" : ""}`} />
        <div className="card-content">
          <h3 className="card-title">Engineering</h3>
          <p className="card-paragraph smaller">
          For engineering & industrial products manufacturing & trading companies, we offer specialised digital marketing services that highlight your expertise, attract domestic and international buyers , and secure new projects. Our strategies are designed to enhance your brand’s visibility and credibility within the industry.
          </p>
        </div>
      </div>
      
      </div></div>
    );
}

export default Home3