import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import AboutUs from './containers/AboutUs';
import { useEffect, useState } from 'react';
import Footer from './components/Footer';
import Services from './containers/Services';
import Technology from './containers/Technology';
import Design from './containers/Design';
import ScrollToTop from './components/ScrollToTop';
import Home from './containers/Home';
import Contact from './containers/Contact';


function App() {

  // Home Page Animation
  const [activeImg, setActiveImg] = useState(false);
  const [activeImgX, setActiveImgX] = useState(false);


  function handleImage() {
    setActiveImg(true);
    setActiveImgX(true);
}



  const [theme, setTheme] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  // Function to change the favicon
  const changeFavicon = (theme) => {
    const favicon = document.getElementById('favicon');
    if (theme === 'light') {
      favicon.href = '/favicon1.png';
    } else {
      favicon.href = '/favicon2.png';
    }
  };

  useEffect(() => {
    // Set initial favicon
    changeFavicon(theme);

    // Listen to system theme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      const newTheme = e.matches ? 'dark' : 'light';
      setTheme(newTheme);
      changeFavicon(newTheme);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [theme]);


  // Auto Adjust Margin after Menu Height

  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    // Function to update menu height and adjust content margin top
    const adjustContentMarginTop = () => {
      const menu = document.getElementById('menu');
      const menuHeight = menu.offsetHeight;
      setMenuHeight(menuHeight);
    };

    // Call the function initially and on window resize
    adjustContentMarginTop();
    window.addEventListener('resize', adjustContentMarginTop);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', adjustContentMarginTop);
    };
  }, []);

    useEffect(() => {
      const link = document.createElement('link');
      link.href = 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap';
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    }, []);

    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    <BrowserRouter>
      <ScrollToTop />


      <Navbar 
        activeImg={activeImg} 
        handleImage={handleImage} 
        setActiveImg={setActiveImg}
        setActiveImgX={setActiveImgX}
      />
      
      <Routes>
        <Route path='/' element={<Home menuHeight={menuHeight} activeImg={activeImg} activeImgX={activeImgX}  />} />
        <Route path='/about' element={<AboutUs menuHeight={menuHeight} />} />
        <Route path='/acquisition' element={<Services menuHeight={menuHeight} />} />
        <Route path='/technology' element={<Technology menuHeight={menuHeight} />} />
        <Route path='/design' element={<Design menuHeight={menuHeight} />} />
        <Route path='/contact' element={<Contact menuHeight={menuHeight} />} />
      </Routes>
      
      <Footer />
    </BrowserRouter>

    </>
  );
}

export default App;
