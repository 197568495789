import React from 'react';
import Home3 from '../components/Home3';
import '../styles/home.css';
import Home4 from '../components/Home4';
import Home5 from '../components/Home5';
import HomeBannerUpdated from '../components/HomeBannerUpdated';
import HomeBannerUpdated2 from '../components/HomeBannerUpdated2';

function Home({menuHeight}) {

  const windiwWidth = window.innerWidth;

  

    return (
      <>
          <section>
              <div className="header" style={{textAlign: "center",  }}>

              {
                (windiwWidth > 990) ?
                <HomeBannerUpdated /> 
                :
                <HomeBannerUpdated2 />
              }

                <Home3/>
                <Home4/>
                <Home5/>
              </div>
          </section>
  
      </>
    )
  }
  
  export default Home